import { parseExpression } from '@/helpers/ExpressionParser';

export default new class  GenerateFileLink {

    parse(data, values) {
        const parsedExpression = parseExpression(data.data.fileName, values, 'unwrap');
        parsedExpression.value = `${process.env.VUE_APP_APP_SERVICE_DOMAIN}/${parsedExpression.value}`;
        return parsedExpression;
    }
};
